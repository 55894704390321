<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <!-- 表格筛选层 -->
    <el-form
      class="table-filter-wrapper base-shadow"
      ref="form"
      :model="tableFilters"
      label-width="100px"
    >
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-form-item label="活动区域">
            <el-select
              v-model="tableFilters.region"
              placeholder="请选择活动区域"
              style="width: 100%"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-form-item label="活动区域">
            <el-select
              v-model="tableFilters.region"
              placeholder="请选择活动区域"
              style="width: 100%"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-form-item label="活动区域">
            <el-select
              v-model="tableFilters.region"
              placeholder="请选择活动区域"
              style="width: 100%"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" v-show="showMoreFilter">
          <el-form-item label="活动区域">
            <el-select
              v-model="tableFilters.region"
              placeholder="请选择活动区域"
              style="width: 100%"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" v-show="showMoreFilter">
          <el-form-item label="活动区域">
            <el-select
              v-model="tableFilters.region"
              placeholder="请选择活动区域"
              style="width: 100%"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" v-show="showMoreFilter">
          <el-form-item label="活动区域">
            <el-select
              v-model="tableFilters.region"
              placeholder="请选择活动区域"
              style="width: 100%"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <div class="table-control-group">
            <el-button @click="onResetFilter" icon="el-icon-delete"
              >重置</el-button
            >
            <el-button
              type="primary"
              @click="onQuery"
              icon="el-icon-search"
              :disabled="tableLoading"
              >查询</el-button
            >
            <el-button
              type="text"
              icon="el-icon-arrow-down"
              @click="showMoreFilter = !showMoreFilter"
              >{{ showMoreFilter ? "收起" : "展开" }}</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表格层 -->
    <section class="table-container base-shadow">
      <div class="table-header">
        <div class="table-header-name">
          查询表格
          <span class="table-header-desc">
            {{
              this.orderDirection !== null
                ? `按照${getSortPropName(
                    tableColumns,
                    this.orderProp
                  )},${getOrderNameCn(this.orderDirection)}排序`
                : "按照默认排序"
            }}</span
          >
        </div>
        <div class="table-btn-group">
          <el-button size="small" class="mt-r">新增数据</el-button>
          <i
            class="el-icon-refresh table-btn-item"
            title="刷新数据"
            @click="clearAndRefrsh"
          ></i>
          <i
            class="el-icon-close table-btn-item"
            title="清空筛选和排序并刷新"
            @click="clearAndRefrsh"
          ></i>
          <el-dropdown
            title="表格密度"
            @command="handleTableSizeChange"
            trigger="click"
            class="table-btn-item"
          >
            <span class="el-dropdown-link">
              <i class="el-icon-s-grid"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="medium">基本</el-dropdown-item>
              <el-dropdown-item command="small">紧凑</el-dropdown-item>
              <el-dropdown-item command="mini">密集</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            class="table-btn-item mt-r"
          >
            <span class="el-dropdown-link">
              <i class="el-icon-setting"></i>
            </span>
            <el-dropdown-menu
              slot="dropdown"
              style="height: 250px; overflow-y: auto"
            >
              <template v-for="item in tableColumns">
                <el-dropdown-item :key="item.lable"
                  ><el-checkbox v-model="item.showColumn">{{
                    item.label
                  }}</el-checkbox></el-dropdown-item
                >
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :size="tableSize"
          ref="multipleTable"
          @sort-change="onTableSortChange"
          :default-sort="{ prop: orderProp, order: orderDirection }"
          v-loading="tableLoading"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            fixed="left"
          />
          <template v-for="column in tableColumns">
            <el-table-column
              v-if="column.prop !== 'controlColumn' && column.showColumn"
              v-bind="column"
              :show-overflow-tooltip="true"
              :key="column.prop"
            >
            </el-table-column>
            <el-table-column
              v-if="column.prop === 'controlColumn' && column.showColumn"
              v-bind="column"
              :key="column.prop"
            >
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editRow(scope.$index, scope.row)"
                  type="text"
                  size="small"
                >
                  修改
                </el-button>
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, scope.row)"
                  type="text"
                  size="small"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="custom-table-footer">
        <div class="multiple-control">
          <el-button
            type="danger"
            size="small"
            :disabled="multipleSelected.length <= 0"
            @click="onMultipleDelete"
            >批量删除 {{ multipleSelected.length }}</el-button
          >
        </div>
        <el-pagination
          class="base-table-pagination"
          :disabled="tableLoading"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page + 1"
          :page-sizes="[20, 40, 60, 80]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </section>
  </section>
</template>

<script>
import { ShowApiError } from "@/request/error";
import { getClientList } from "./api";
import { getOrderNameCn, getOrderType, getSortPropName } from "@/utils/common";
import PageHeader from "@/components/PageHeader";
export default {
  components:{
    PageHeader
  },
  data() {
    return {
      showMoreFilter: false,
      tableLoading: false,
      tableColumns: [],
      tableSize: "medium",
      page: 0,
      size: 20,
      total: 20,
      tableData: [],
      orderProp: "",
      orderDirection: null,
      tableFilters: {
        region: "",
      },
      multipleSelected: [],
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    getOrderNameCn,
    getOrderType,
    getSortPropName,
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {};
    },
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "id",
            label: "ID",
            showColumn: true,
            sortable: "custom",
            width: "180",
          },
          {
            prop: "name",
            label: "姓名",
            showColumn: true,
            sortable: "custom",
            width: "180",
          },
          {
            prop: "contactName",
            label: "联系人",
            showColumn: true,
            sortable: "custom",
            width: "180",
          },
          {
            prop: "ownerName",
            label: "负责人",
            showColumn: true,
            sortable: "custom",
            width: "180",
          },
          {
            prop: "clientType",
            label: "客户类型",
            showColumn: true,
            sortable: "custom",
            width: "180",
          },
          {
            prop: "tagName",
            label: "客户标签",
            showColumn: true,
            sortable: "custom",
            width: "180",
          },
          { prop: "city", label: "地址", sortable: "custom", showColumn: true },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: "120",
          },
        ];
      }
    },
    getTableData() {
      this.tableLoading = true;
      getClientList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    getTableOrderParams() {
      if (this.orderProp !== "" && this.orderDirection !== null) {
        return `${this.orderProp},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    onTableSortChange({ prop, order }) {
      this.orderDirection = order;
      if (order === null) {
        this.orderProp = "";
      } else {
        this.orderProp = prop;
      }
      this.onRefresh();
    },
    onResetFilter() {
      for (const key in this.tableFilters) {
        if (typeof this.tableFilters[key] === "object") {
          if (this.tableFilters[key] instanceof Array) {
            this.tableFilters[key] = [];
          } else {
            this.tableFilters[key] = null;
          }
        } else {
          this.tableFilters[key] = "";
        }
      }
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onQuery() {},
    clearAndRefrsh() {
      this.orderProp = "";
      this.orderDirection = null;
      this.onResetFilter();
      this.onRefresh();
    },
    handleTableSizeChange(command) {
      this.tableSize = command;
    },
    editRow() {},
    deleteRow() {},
    handleSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    onMultipleDelete() {
      this.$msgbox({
        title: "批量删除提示",
        message: "是否确定要批量删除这些数据？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            let timer = null;
            timer = setTimeout(() => {
              this.multipleSelected = [];
              this.$refs.multipleTable.clearSelection();
              this.$message.success("删除成功");
              clearTimeout(timer);
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = "";
              done();
            }, 2000);
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$base-bg-color: #fff;
.common-bg-color {
  background-color: $base-bg-color;
}
.base-space {
  padding: 15px;
  margin: 15px;
}
.page-header {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  @extend .common-bg-color;
  .header-title {
    font-size: 22px;
  }
}
.table-filter-wrapper {
  box-sizing: border-box;
  @extend .base-space;
  padding-bottom: 0;
  padding-top: 22px;
  @extend .common-bg-color;
}
.table-control-group {
  text-align: center;
  margin-bottom: 22px;
}
.table-container {
  box-sizing: border-box;
  @extend .common-bg-color;
  margin: 10px;
  .table-header {
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .table-header-name {
      font-size: 15px;
      color: #474747;
      padding-left: 10px;
    }
    .table-header-desc {
      font-size: 13px;
      color: #989898;
      padding-left: 8px;
    }
  }
  .table-content {
    box-sizing: border-box;
    width: 100%;
  }
  .custom-table-footer {
    padding: 15px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .multiple-control {
      flex: 1;
      height: 100%;
      text-align: left;
    }
  }
  .table-btn-group {
    display: flex;
    align-items: center;
    .table-btn-item {
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
</style>